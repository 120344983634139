<template>
    <div :class="{ 'nav-open': showSidebar }">
        <div class="main-content wrapper">
            <Navbar
                v-if="userSelf"
                :systemTitle="sidebarTitle"
                :pageTitle="pageTitle"
                :userSelf="userSelf"
                @toggle-sidebar="toggleSidebar"
            />
            <side-bar
                :logo="systemLogo"
                :title="sidebarTitle"
                :background-color="sidebarBackgroundColor"
                :active-color="sidebarActiveColor"
                :sidebar-links="sidebarLinks"
            >
                <template v-slot:links>
                    <sidebar-link
                        v-for="(link, index) in sidebarLinks"
                        :key="index"
                        :to="link.path"
                        :name="link.name"
                        :icon="link.icon"
                    />
                </template>
            </side-bar>
            <div>
                <div class="content">
                    <div class="container-fluid">
                        <router-view v-slot="{ Component }">
                            <transition name="fade" mode="out-in">
                                <component :is="Component" />
                            </transition>
                        </router-view>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted, computed, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import Navbar from '@/components/Navbar/Navbar.vue';
import { userSelf as fetchUserSelf } from '@/api/users';

export default {
    components: {
        Navbar,
    },
    setup() {
        const { t } = useI18n();
        const store = useStore();
        const route = useRoute();

        const userSelf = ref(null);
        const menuItems = ref([]);
        const pageTitle = ref('');

        const getPageTitle = routePath => {
            const page = menuItems.value.find(page => page.path === routePath);
            return page
                ? t(`sidebar.sidebar_msg__${page.name}`)
                : 'Unknown Page';
        };

        const getUserSelf = async () => {
            try {
                // wait for 100ms
                await new Promise(resolve => setTimeout(resolve, 100));
                const { user_data, menu_items } = await fetchUserSelf();
                userSelf.value = user_data;
                menuItems.value = menu_items;
                pageTitle.value = getPageTitle(route.path);
            } catch (error) {
                console.error('Error fetching user self:', error);
            }
        };

        const getMenuItemsTranslated = () => {
            return menuItems.value.map(item => {
                return {
                    name: t(`sidebar.sidebar_msg__${item.name}`),
                    path: item.path,
                    icon: item.icon_class_name,
                };
            });
        };

        const systemLogo = computed(() => {
            const logoBase64 = store.getters['system/logo'];
            if (logoBase64) {
                return `data:image/png;base64,${logoBase64}`;
            }
            return `${process.env.BASE_URL}assets/img/dhc-logo.png`;
        });

        const sidebarTitle = computed(() => {
            return store.getters['system/title'] || 'Club Assist SaaS';
        });

        const sidebarBackgroundColor = ref('black');
        const sidebarActiveColor = ref('primary');

        const showSidebar = computed(() => store.state.sidebar.showSidebar);
        const sidebarLinks = computed(() => store.state.sidebar.sidebarLinks);

        const toggleSidebar = () => {
            store.dispatch('sidebar/toggleSidebar');
        };
        const setSidebarLinks = links => {
            store.dispatch('sidebar/setSidebarLinks', links);
        };

        const fetchSidebarLinks = async () => {
            const translatedLinks = getMenuItemsTranslated();
            setSidebarLinks(translatedLinks);
        };

        onMounted(async () => {
            await getUserSelf();
            fetchSidebarLinks();
        });

        watchEffect(() => {
            fetchSidebarLinks();
            pageTitle.value = getPageTitle(route.path);
        });

        return {
            systemLogo,
            sidebarTitle,
            sidebarBackgroundColor,
            sidebarActiveColor,
            showSidebar,
            sidebarLinks,
            toggleSidebar,
            pageTitle,
            userSelf,
        };
    },
};
</script>

<style lang="scss">
.layout {
    display: flex;
    flex-direction: column;
}

.nav-open .main-content {
    margin-left: 260px; /* Adjust based on your sidebar width */
}

.main-content {
    flex-grow: 1;
    transition: margin-left 0.3s;
}

.sidebar {
    transition: transform 0.3s;
    transform: translateX(-100%);
}

.nav-open .sidebar {
    transform: translateX(0);
}

.content {
    padding: 20px;
}
</style>
