import apiClient from '@/api/index';
import { setLoading } from '@/plugins/loading';

export const fetchCheckCableResultFromAPI = async params => {
    try {
        const response = await apiClient.get('/diagnosis/check-cable', {
            params,
        });
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const exportCheckCableFromAPI = async (params, type) => {
    try {
        if (localStorage.getItem('locale') !== undefined) {
            params.output_language = localStorage.getItem('locale');
        }
        const response = await apiClient.get(
            `/diagnosis/check-cable/export/${type}`,
            {
                params,
                responseType: 'blob',
            }
        );
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    }
};

export const fetchConfig = async () => {
    try {
        setLoading(true);
        const response = await apiClient.get('/diagnosis/check-cable/configs');
        return response.data;
    } catch (error) {
        console.error(error);
        throw error;
    } finally {
        setLoading(false);
    }
};
